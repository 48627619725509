import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from 'react-dom/client';
import process from 'process';
import EnvLine from '@ludo.ninja/ui/build/components/envLine';
var EnvTitle = function () {
    var envValue = process.env.ENV_VALUE !== 'prod' ? process.env.ENV_VALUE : null;
    return _jsx(EnvLine, { isNeedToShow: envValue });
};
export var initializeEnvTitleReact = function () {
    var rootElement = document.getElementById('envLine');
    var root = ReactDOM.createRoot(rootElement);
    root.render(_jsx(EnvTitle, {}));
};
